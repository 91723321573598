// Init jQuery
const $ = require("jquery");
window.jQuery = $;
window.$ = $;
require('popper.js');

// Init jquery-form
require("jquery-form");

// Init Boostrap
import 'bootstrap';

// Init Bootstrap icons
require('bootstrap-icons/font/bootstrap-icons.scss');

// Init our Utils
import commonJS from './modules/common.js';
window.showAlert = commonJS.showAlert;
window.showFormErrors = commonJS.showFormErrors;
window.clearFormErrors = commonJS.clearFormErrors;
window.getParams = commonJS.getParams;
window.showLoader = commonJS.showLoader;
window.hideLoader = commonJS.hideLoader;
window.initTooltip = commonJS.initTooltip;

// Some global JS
$(document).ready(function () {
    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
    });
});
