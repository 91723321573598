function showAlert(message, type, is_dismissible) {
    var button = '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>';

    if(is_dismissible) {
        return '<div class="alert ' + type + ' alert-dismissible fade show" role="alert">' + message + button + '</div>';
    } else {
        return '<div class="alert ' + type + '" role="alert">' + message + '</div>';
    }
}

function showFormErrors(form, errors) {
    //clear existing errors
    form.find($(".is-invalid")).removeClass('is-invalid');
    form.find($(".invalid-feedback")).remove();

    //highlight errors, if any
    $.each(errors, function(key, value) {
        form.find($("#" + key)).addClass('is-invalid');
        $("<div class='invalid-feedback'>" + value + "</div>").insertAfter(form.find($("#" + key)));
    });
}

function clearFormErrors(form) {
    //clear existing errors
    form.find($(".is-invalid")).removeClass('is-invalid');
    form.find($(".invalid-feedback")).remove();
}

function getParams(target) {
    var params = {};
    target.find("input[type='text'], input[type='email'], input[type='hidden'], textarea, select").each( function() {
        params[this.name || this.id] = this.value;
    });
    target.find("input[type='checkbox']").each( function() {
        params[this.name || this.id] = this.checked;
    });
    target.find("input[type='radio']:checked").each( function() {
        params[this.name] = this.value;
    });
    target.find("input[type='file']").each( function() {
        params[this.name] = this.value;
    });

    params['_token'] = $("input[name='_token']").val();
    return params;
}

function showLoader() {
    $("div#loader").removeClass("d-none");
}

function hideLoader() {
    $("div#loader").addClass("d-none");
}

function initTooltip() {
    $('[data-toggle="tooltip"]').tooltip({
        html: true
    });
}
export default {
    showAlert,
    showFormErrors,
    clearFormErrors,
    getParams,
    showLoader,
    hideLoader,
    initTooltip,
}
